import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import { Product, SubCategory } from '../types/product';
import Carousel from 'react-elastic-carousel';
import ImageZoom from '../components/ImageZoom';
import { useTypedSelector } from '../store';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

function ProductElement() {
  const { t } = useTranslation();
  const { productUID } = useParams<{ productUID: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [subCategory, setSubCategory] = useState<SubCategory | null>(null);
  const language = useTypedSelector((state) => state.app.language);
  const carousel = useRef<Carousel>(null);
  const products = useTypedSelector((state) => state.app.products);
  const categories = useTypedSelector((state) => state.app.categories);
  const [carouselImagesToShow, setCarouselImagesToShow] = useState(4);

  useEffect(() => {
    const current = carousel?.current;
    // cast to any because goto does not exists on TS type for carousel
    (current as any)?.goTo(0);
  }, [product]);

  useEffect(() => {
    const p = products.find((p) => p.uid === productUID);
    setProduct(p || null);
    const c = categories.find((c) => c.uid === p?.category);
    const sc = c?.subCategories.find((sc) => sc.id === p?.subCategory);
    setSubCategory(sc || null);
  }, [productUID, products, categories]);

  const calculateCarouselImagesToShow = () => {
    console.log(window.innerWidth);
    if (window.innerWidth >= 1440) {
      setCarouselImagesToShow(4);
    } else if (window.innerWidth >= 1140) {
      setCarouselImagesToShow(3);
    } else if (window.innerWidth >= 840) {
      setCarouselImagesToShow(2);
    } else {
      setCarouselImagesToShow(1);
    }
  };

  return product ? (
    <div className="ProductElementAlone">
      <div className="Breadcrumb">
        <Link to={`/${product.category}`}>{t(product.category)}</Link>
        <Link to={`/${product.category}/${product.subCategory}`}>
          {language === 'fr' ? subCategory?.nameFR : subCategory?.nameEN}
        </Link>
        <div className="fakeBreadcrumbLink">{product.nameFR}</div>
      </div>
      <div className="Name">
        {language === 'fr' ? product.nameFR : product.nameEN}
      </div>
      <div className="ProductAloneCarousel">
        {product.images?.length ? (
          <Carousel
            itemsToShow={carouselImagesToShow}
            onResize={calculateCarouselImagesToShow}
            isRTL={false}
            ref={carousel}
          >
            {product.images?.map((image, index) => (
              <div key={index} className="CarouselItem">
                <ImageZoom
                  alt={language === 'fr' ? product.nameFR : product.nameEN}
                  key={index}
                  image={image}
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <div className="CarouselPlaceHolderItem">
            <img alt="placeholder" src="/placeholder.png" />
          </div>
        )}
      </div>
      <div className="Detail">
        <div className="Description">
          {language === 'fr' ? product.descriptionFR : product.descriptionEN}
        </div>
        <div className="ComponentsAndPrice">
          <div className="Components">
            <div className="label">{t('product.components')}</div>
            <div className="value">
              {language === 'fr' ? product.componentsFR : product.componentsEN}
            </div>
          </div>
          <div className="Price">
            <div className="label">{t('product.price')}</div>
            <div className="value">{product.price}€</div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default ProductElement;
