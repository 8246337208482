import '../App.css';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';
import { Drawer } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

function MobileMenu() {
  const [opened, setOpened] = React.useState(false);

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="MobileMenuButton">
        <IconButton
          color="primary"
          aria-label="Menu"
          onClick={() => {
            setOpened(true);
          }}
        >
          <Menu fontSize="large" />
        </IconButton>
      </div>

      <Drawer
        anchor="left"
        open={opened}
        onClose={() => {
          setOpened(false);
        }}
      >
        <div
          className="MobileMenu"
          onClick={() => {
            setOpened(false);
          }}
        >
          <div className="Logo">
            <img src="/LogoHeader.svg" alt="logo" />
          </div>
          <div className="MobileMenuItem">
            <Link to="/harnachement">{t('harnachement')}</Link>
          </div>
          <div className="MobileMenuItem">
            <Link to="/canin">{t('canin')}</Link>
          </div>
          <div className="MobileMenuItem">
            <Link to="/maroquinerie">{t('maroquinerie')}</Link>
          </div>
          <div className="MobileMenuItem">
            <Link to="/personnalisation">{t('personnalisation')}</Link>
          </div>
          <div className="MobileMenuItem">
            <Link to="/reparation">{t('reparation')}</Link>
          </div>
          <div className="MobileMenuItem">
            <Link to="/stock">{t('stock')}</Link>
          </div>
          <div className="MobileMenuItem">
            <Link to="/about">{t('about')}</Link>
          </div>
          <div className="MobileMenuItem">
            <Link to="/contact">{t('contact')}</Link>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default MobileMenu;
