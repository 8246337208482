import '../App.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTypedSelector } from '../store';

function Footer() {
  const { t } = useTranslation();
  const contact = useTypedSelector((state) => state.app.contact);

  return (
    <div className="Footer">
      <div className="wrapper">
        <div className="Address">
          <div className="Title">
            {contact.firstname} {contact.lastname}
          </div>
          <div className="AddressL1">
            {contact.address1} {contact.address2}
          </div>
          <div className="AddressL1">
            {contact.zipCode} {contact.city}
          </div>
          <div className="AddressL4">{contact.telephone}</div>
          <div className="AddressL3">{contact.email}</div>
          <div className="Logo">
            <img src="/LogoHeader.svg" alt="logo" />
          </div>
        </div>

        <div className="more-info">
          <div className="more-info-item">
            <Link to="/about">{t('qui-suis-je')}</Link>
          </div>
          <div className="more-info-item">
            <Link to="/contact">{t('contact')}</Link>
          </div>
          <div className="more-info-item">
            <Link to="/how-to-order">{t('comment-commander')}</Link>
          </div>
        </div>

        <div className="more-info">
          <div className="more-info-item">
            <a href="https://www.guichet-entreprises.fr/fr/ds/tourisme-loisirs-culture/artisan-d-art.html">
              Artisan d'Art
            </a>
          </div>
          <div className="more-info-item">
            <Link to="/entretien">{t('entretien')}</Link>
          </div>
          <div className="more-info-item">
            <Link to="/cgv">{t('cgv')}</Link>
          </div>
        </div>

        <div className="Social">
          <div className="Instagram">
            <a href="https://www.instagram.com/selleriekirin/?hl=fr">
              <img src="/instagram.svg" alt="instagram" />
            </a>
          </div>
          <div>
            <div
              className="fb-page"
              data-href="https://www.facebook.com/selleriekirin"
              data-tabs=""
              data-width="230"
              data-height="70"
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="false"
            >
              <blockquote
                cite="https://www.facebook.com/selleriekirin"
                className="fb-xfbml-parse-ignore"
              >
                <a href="https://www.facebook.com/selleriekirin">
                  Sellerie Kirin
                </a>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
