import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      harnachement: 'Horse corner',
      canin: 'Dog corner',
      maroquinerie: 'Leather goods',
      personnalisation: 'Customization',
      stock: 'In Stock',
      about: 'About',
      reparation: 'Repairs',
      contact: 'Contact',
      'Pourquoi le sur mesure ?': 'Why tailored made?',
      'Pourquoi ?': 'Why?',
      "Danger d'un matériel mal adapté": 'Danger of misfit equipment',
      'Comment ?': 'How?',
      Garanties: 'Guarantees',
      'Chiens (colliers, harnais, porte-médaille)':
        'Dogs (collar, harness, etc.)',
      'Chevaux (licol, filet, bride, side-pull, etc.)':
        'Horses (halter, bridle, side-pull, etc.)',
      'Maroquinerie (ceinture, bracelet, etc. )':
        'Leather goods (belt, bracelets, etc.)',
      'Fiches de prises de mesures': 'Fiches de prises de mesures',
      'Sur mesure': 'Tailored made',
    },
  },
  fr: {
    translation: {
      /* categories */
      harnachement: 'Harnachement',
      canin: 'Articles canins',
      maroquinerie: 'Maroquinerie',
      personnalisation: 'Personnalisation',
      stock: 'En Stock',
      about: 'A propos',
      reparation: 'Réparations',
      contact: 'Contact',
      /* TODO faire le ménage */
      'licols-licous': 'Licols et Licous',
      'monte-sans-mors': 'Monte sans Mors',
      'brides-bridons': 'Brides et Bridons',
      'renes-longes': 'Rênes et Longes',
      'monte-amazone': 'Monte en Amazone',
      'accessoires-selle': 'Accessoires de Selle',
      'autres-animaux': 'Autres Animaux',
      colliers: 'Colliers',
      'porte-médailles': 'Porte-médailles',
      harnais: 'Harnais',
      laisses: 'Laisses',
      martingales: 'Martingales',
      'sacs-sacoches': 'Sacs et Sacoches',
      ceintures: 'Ceintures',
      portefeuilles: 'Portefeuilles',
      bijoux: 'Bijoux',
      autre: 'Autre',
      'sur-mesure': 'Sur Mesure',
      'cuir-fils-boucles': 'Cuir, Fils et Boucles',
      decoration: 'Décoration',
      'qui-suis-je': 'À propos',
      entretien: 'Entretien',
      reparations: 'Réparations',
      'comment-commander': 'Comment commander ?',
      cgv: 'CGV',
      'contact-title': 'Contact',
      'contact-subtitle': `N'hésitez pas à me contacter pour un devis ou une question relative à un article. Je vous répondrais le plus rapidement possible avec plaisir.`,
      'contact-name': 'Nom',
      'contact-phone': 'Téléphone',
      'contact-email': 'Adresse Email',
      'contact-object': 'Objet',
      'contact-category': 'Categorie',
      'contact-product': 'Article',
      'contact-message': 'Message',
      'contact-question': 'Question',
      'contact-devis': 'Devis',
      'contact-other': 'Autre',
      'contact-send': 'Envoyer',
      'home-leather.title': 'Des matériaux de premier choix',
      'home-leather.text':
        'Cuirs pleine fleur de premier choix au tannage végétal, fil de lin ciré à la main et boucles acier inoxydable ou laiton, nous mettons tout en œuvre pour vous proposer des articles d’une qualité irréprochable aussi durables et résistants que beaux.',
      'home-know-how.title': 'Un savoir-faire ancestral',
      'home-know-how.text':
        'Tous nos articles sont réalisés en suivant des techniques anciennes ayant fait leurs preuves. Entre autre, la couture à la main au point sellier assure une longévité sans égal et la découpe manuelle au couteau à pied nous permet toutes les fantaisies.',
      'home-modern.title': 'Ce qu’il faut de modernité',
      'home-modern.text':
        'Formée par une ostéopathe à l’adaptation du harnachement, je me tiens régulièrement au courant des recherches en biomécanique animale afin d’offrir un matériel toujours plus adapté à vos animaux. L’utilisation de nouvelles techniques de montage ou de travail est suivie de nombreux tests pour assurer une évolution vers des articles toujours plus qualitatifs.',
      'product.components': 'Composants:',
      'product.price': 'Prix:',
      'contact.no-products': 'Aucun produit',
      'about-title': 'Qui suis-je ?',
      'about-text':
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      'about-signature': " - Artisan d'Art",
      'repairs-title': 'Réparations',
      'repairs-legend-1': "Couture abimée d'une selle western",
      'repairs-legend-2': "Changement de sanglon d'une muserolle",
      'repairs-text':
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      'howToOrder-title': 'Comment commander ?',
      'howToOrder-title1': 'Vous me contactez',
      'howToOrder-via': 'Par',
      'howToOrder-site-form': 'le formulaire du site',
      'howToOrder-facebook': "la page Facebook de l'atelier",
      'howToOrder-email': 'email',
      'howToOrder-telephone': 'Par téléphone ou SMS au',
      'howToOrder-title2': 'Nous discutons de votre projet',
      'howToOrder-carac': 'caractéristiques techniques',
      'howToOrder-custom': 'Personnalisation des motifs, formes et couleurs',
      'howToOrder-answer': 'Réponses à vos diverses questions',
      'howToOrder-devis': 'Réalisation du devis',
      'howToOrder-title3': 'Prise de mesures',
      'howToOrder-text3-a':
        "Si vous êtes à proximité, je me déplace jusqu'à vous ou vous venez à l'atelier en profiter pour voir nos cuirs en vrai",
      'howToOrder-text3-b':
        'Si vous êtes trop loin, je vous fourni un PDF explicatif pour la prise de mesures par vos soins et reste joignable en cas de doute',
      'howToOrder-title4': 'Je fabrique vos atricles',
      'howToOrder-text4':
        "Les délais de fabrications vous sont annoncés à l'avance, ceux-ci peuvent vaier selon la période et la complexité de la demande",
      'howToOrder-title5': 'Vous récupérez votre article',
      'howToOrder-text5-a': "En venant le chercher à l'atelier",
      'howToOrder-text5-b': 'Par livraison en Mondial Relay ou Colissimo',
      'howToOrder-think-read': 'Pensez à lire nos',
      CGV: 'CGV',
    },
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
