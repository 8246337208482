import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../store';
import Carousel from 'react-elastic-carousel';
import { Image } from '../types/image';

function ContactElement() {
  const { t } = useTranslation();
  const products = useTypedSelector((state) => state.app.products);
  const [images, setImages] = useState<Array<Image>>([]);
  const carousel = useRef<Carousel>(null);
  const [carouselImagesToShow, setCarouselImagesToShow] = useState(4);
  const carouselTimer = 5000;

  const calculateCarouselImagesToShow = () => {
    console.log(window.innerWidth);
    if (window.innerWidth >= 1440) {
      setCarouselImagesToShow(4);
    } else if (window.innerWidth >= 1140) {
      setCarouselImagesToShow(3);
    } else if (window.innerWidth >= 840) {
      setCarouselImagesToShow(2);
    } else {
      setCarouselImagesToShow(1);
    }
  };

  useEffect(() => {
    if (products) {
      setImages(
        products
          .reduce((previous, current) => {
            return [...previous, ...current.images];
          }, [] as Array<Image>)
          .sort(() => (Math.random() > 0.5 ? 1 : -1))
          .slice(0, 14)
      );
    }
  }, [products]);

  return (
    <div className="Home">
      <div className="Carousel">
        {images.length ? (
          <Carousel
            ref={carousel}
            isRTL={false}
            itemsToShow={carouselImagesToShow}
            itemsToScroll={carouselImagesToShow}
            enableAutoPlay
            autoPlaySpeed={carouselTimer}
            onResize={calculateCarouselImagesToShow}
            onNextEnd={(currentItem, pageIndex) => {
              if (
                pageIndex ===
                Math.ceil(images.length / carouselImagesToShow) - 1
              ) {
                setTimeout(() => {
                  if (carousel.current) {
                    (carousel.current as any).goTo(0);
                  }
                }, carouselTimer);
              }
            }}
          >
            {images.map((image, index) => (
              <div key={index} className="CarouselItem">
                <img
                  alt="random pic from products"
                  key={index}
                  src={image.medium}
                />
              </div>
            ))}
          </Carousel>
        ) : null}
      </div>
      <div className="Home-content">
        <div className="Column">
          <div className="Image">
            <img src="/ImageCuir.png" alt="leather" />
          </div>
          <div className="Title">{t('home-leather.title')}</div>
          <div className="Text">{t('home-leather.text')}</div>
        </div>
        <div className="Column">
          <div className="Image">
            <img src="/ImageSavoirFaire.png" alt="know-how" />
          </div>
          <div className="Title">{t('home-know-how.title')}</div>
          <div className="Text">{t('home-know-how.text')}</div>
        </div>
        <div className="Column">
          <div className="Image">
            <img src="/ImageModernité.png" alt="modern" />
          </div>
          <div className="Title">{t('home-modern.title')}</div>
          <div className="Text">{t('home-modern.text')}</div>
        </div>
      </div>
    </div>
  );
}

export default ContactElement;
