import React, { useEffect } from 'react';
import './App.css';
import Header from './header/Header';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from './store';
import {
  setProducts,
  setSubCategories,
  setCustomizations,
  setCategories,
} from './reducers/AppReducer';
import {
  getProducts,
  getSubCategories,
  getCustomizations,
  getCategories,
} from './firebase';
import Footer from './footer/Footer';
import Category from './pages/Category';
import SubCategoryAlone from './pages/SubCategoryAlone';
import AdminSubCategories from './pages/admin/SubCategories';
import AdminProducts from './pages/admin/Products';
import ProductElementAlone from './pages/ProductAlone';
import Customizations from './pages/CustomizationsFront';
import ContactElement from './pages/Contact';
import Home from './pages/Home';
import AdminCustomizations from './pages/admin/Customizations';
import AdminCategories from './pages/admin/Categories';
import About from './pages/About';
import Repairs from './pages/Repairs';
import HowToOrder from './pages/HowToOrder';

function App() {
  const categories = useTypedSelector((state) => state.app.categories);
  const productsRedux = useTypedSelector((state) => state.app.products);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!productsRedux?.length) {
      const refreshProducts = async () => {
        const categories = await getCategories();
        await dispatch(setCategories(categories));
        const subCategories = await getSubCategories();
        await dispatch(setSubCategories(subCategories));
        const products = await getProducts();
        dispatch(setProducts(products));
        const customizations = await getCustomizations();
        dispatch(setCustomizations(customizations));
      };
      refreshProducts();
    }
  }, [dispatch, productsRedux]);

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="Page">
          <Switch>
            {categories &&
              categories.map((category) => (
                <Route exact key={category.uid} path={`/${category.uid}`}>
                  <Category category={category} />
                </Route>
              ))}
            {categories &&
              categories.map((category) => {
                return (
                  category.subCategories &&
                  category.subCategories.map((sc) => (
                    <Route
                      exact
                      key={`${category.uid}${sc.id}`}
                      path={`/${category.uid}/${sc.id}`}
                    >
                      <SubCategoryAlone subCategory={sc} category={category} />
                    </Route>
                  ))
                );
              })}
            <Route exact path={`/product/:productUID`}>
              <ProductElementAlone />
            </Route>
            <Route path="/personnalisation">
              <Customizations></Customizations>
            </Route>
            <Route path="/reparation">
              <Repairs />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/how-to-order">
              <HowToOrder />
            </Route>
            <Route path="/contact">
              <ContactElement />
            </Route>
            <Route path="/admin-products">
              <AdminProducts />
            </Route>
            <Route path="/admin-subcategories">
              <AdminSubCategories />
            </Route>
            <Route path="/admin-categories">
              <AdminCategories />
            </Route>
            <Route path="/admin-customizations">
              <AdminCustomizations />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/*">Not found</Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
