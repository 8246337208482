import React, { useState } from 'react';
import '../../App.css';
import { useTypedSelector } from '../../store';
import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { customizationCategory } from '../../types/customization';
import UpsertCustomizationCategory from './components/UpsertCustomizationCategory';

function AdminCustomizations() {
  const user = useTypedSelector((state) => state.app.user);
  const customizations = useTypedSelector((state) => state.app.customizations);
  const addCustomizationCategory = () => {
    selectCustomizationCategory({
      uid: '',
      nameFR: 'Nouveau',
      nameEN: 'New',
      descriptionFR: '',
      descriptionEN: '',
      items: [],
    });
  };
  const [
    selectedCustomizationCategory,
    selectCustomizationCategory,
  ] = useState<customizationCategory | null>(null);

  return (
    <div className="Admin">
      {user ? (
        <div className="AdminRoot">
          <div className="ProductList">
            <div className="addCustomizationCategory">
              <IconButton
                color="primary"
                aria-label="add customization category"
                onClick={() => {
                  addCustomizationCategory();
                }}
              >
                <Add />
              </IconButton>
            </div>
            {customizations?.map((customizationCategory, index) => (
              <React.Fragment key={customizationCategory.uid}>
                <div
                  key={index}
                  onClick={() => {
                    selectCustomizationCategory(customizationCategory);
                  }}
                  data-selected={
                    customizationCategory.uid ===
                    selectedCustomizationCategory?.uid
                  }
                  className="ProductListItem"
                >
                  {customizationCategory.nameFR}
                </div>
              </React.Fragment>
            ))}
          </div>
          {selectedCustomizationCategory ? (
            <div>
              <UpsertCustomizationCategory
                customizationCategory={selectedCustomizationCategory}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        'Forbidden'
      )}
    </div>
  );
}

export default AdminCustomizations;
