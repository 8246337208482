import React from 'react';
import '../App.css';
import LanguageChoice from './LanguageChoice';

function LanguageSelect() {
  return (
    <div className="LanguageSelect">
      <LanguageChoice name="fr" icon="/FR.svg" />
      <LanguageChoice name="en" icon="/EN.svg" />
    </div>
  );
}

export default LanguageSelect;
