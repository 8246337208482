import React, { ChangeEvent, useState } from 'react';
import '../App.css';
import { Product, SubCategory } from '../types/product';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../store';
import { MenuItem, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect } from 'react';

function ContactElement() {
  const { t } = useTranslation();
  const language = useTypedSelector((state) => state.app.language);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [object, setObject] = useState('question');
  const [product, setProduct] = useState<Product | null>(null);
  const [subCategory, setSubCategory] = useState<SubCategory | null>(null);
  const [message, setMessage] = useState('');
  const [products, setProducts] = useState<Array<Product>>([]);
  const subCategories = useTypedSelector((state) => state.app.subCategories);
  const contact = useTypedSelector((state) => state.app.contact);
  const categories = useTypedSelector((state) => state.app.categories);
  useEffect(() => {
    if (subCategory) {
      const sc = categories
        .find((c) => c.uid === subCategory.category)
        ?.subCategories.find((sc) => sc.id === subCategory.id);
      if (sc) {
        setProducts(sc?.products || []);
      }
    }
  }, [subCategory, categories]);

  const sendMail = () => {
    let email = `mailto:${contact.email}`;
    email += `?subject=${object}%20-%20${encodeURIComponent(
      (language === 'fr' ? product?.nameFR : product?.nameEN) || ''
    )}`;
    email += `&body=${encodeURIComponent(
      `Nom: ${name}\nTéléphone: ${phone}\nObjet: ${object}\nCategorie: ${
        subCategory?.nameFR || ''
      }\nArticle: ${product?.nameFR || ''}\nMessage:\n${message}`
    )}`;
    window.open(email);
  };

  const handleValueChanged = (
    property: string,
    { target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    switch (property) {
      case 'name':
        setName(target.value);
        break;
      case 'phone':
        setPhone(target.value);
        break;
      case 'object':
        setObject(target.value);
        break;
      case 'message':
        setMessage(target.value);
        break;
    }
  };

  const handleProductChanged = (_e: ChangeEvent<{}>, newValue: Product) => {
    setProduct(newValue);
  };

  const handleSubCategoryChanged = (
    _e: ChangeEvent<{}>,
    newValue: SubCategory
  ) => {
    setSubCategory(newValue);
  };

  return (
    <div className="Contact">
      <div className="Title">{t('contact-title')}</div>
      <div className="SubTitle">{t('contact-subtitle')}</div>

      <div className="Form">
        <div className="Contact-l1">
          <TextField
            label={t('contact-name')}
            required
            variant="outlined"
            value={name}
            onChange={(e) => {
              handleValueChanged('name', e);
            }}
          />
          <TextField
            label={t('contact-phone')}
            variant="outlined"
            value={phone}
            onChange={(e) => {
              handleValueChanged('phone', e);
            }}
          />
        </div>
        <TextField
          select
          fullWidth
          required
          value={object}
          label={t('contact-object')}
          onChange={(e) => {
            handleValueChanged('object', e);
          }}
          variant="outlined"
        >
          <MenuItem value="devis">{t('contact-devis')}</MenuItem>
          <MenuItem value="question">{t('contact-question')}</MenuItem>
          <MenuItem value="other">{t('contact-other')}</MenuItem>
        </TextField>

        {object === 'devis' ? (
          <React.Fragment>
            <Autocomplete
              options={subCategories}
              autoHighlight
              disableClearable
              onChange={handleSubCategoryChanged}
              getOptionLabel={(subCategory) =>
                `${t(subCategory.category)} - ${
                  language === 'fr' ? subCategory.nameFR : subCategory.nameEN
                }`
              }
              renderOption={(subCategory) => (
                <React.Fragment>
                  <span>
                    {t(subCategory.category)} -{' '}
                    {language === 'fr'
                      ? subCategory.nameFR
                      : subCategory.nameEN}
                  </span>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('contact-category')}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {subCategory ? (
              <Autocomplete
                options={products}
                noOptionsText={t('contact.no-products')}
                autoHighlight
                disableClearable
                onChange={handleProductChanged}
                getOptionLabel={(option) =>
                  language === 'fr' ? option.nameFR : option.nameEN
                }
                renderOption={(option) => (
                  <React.Fragment>
                    <span>
                      {language === 'fr' ? option.nameFR : option.nameEN}
                    </span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('contact-product')}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            ) : null}
          </React.Fragment>
        ) : null}
        <TextField
          label={t('contact-message')}
          rows={4}
          fullWidth
          required
          multiline
          variant="outlined"
          value={message}
          onChange={(e) => {
            handleValueChanged('message', e);
          }}
        />
      </div>
      <div className="Contact-action">
        <Button
          disabled={!(name && object && message)}
          onClick={sendMail}
          variant="contained"
          color="primary"
        >
          {t('contact-send')}
        </Button>
      </div>
    </div>
  );
}

export default ContactElement;
