import React, { useEffect, useRef, useState } from 'react';
import '../../App.css';
import UpsertProduct from './components/UpsertProduct';
import { useTypedSelector } from '../../store';
import { IconButton, TextField } from '@material-ui/core';
import { Add, CloudDownload, CloudUpload } from '@material-ui/icons';
import {
  customizationApi,
  productsApi,
  subCategoriesApi,
} from '../../firebase';
import { ImportFileModel, Product } from '../../types/product';

const filterProducts = (products: Array<Product>, search: string) => {
  return products
    .filter((product) => {
      return (
        product.nameFR.toLowerCase().includes(search.toLowerCase()) ||
        product.nameEN.toLowerCase().includes(search.toLowerCase()) ||
        product.descriptionFR.toLowerCase().includes(search.toLowerCase()) ||
        product.descriptionEN.toLowerCase().includes(search.toLowerCase()) ||
        product.category.toLowerCase().includes(search.toLowerCase()) ||
        product.subCategory.toLowerCase().includes(search.toLowerCase())
      );
    })
    .sort((a, b) => {
      // sort by category and subcategory and then by nameFR
      if (a.category < b.category) {
        return -1;
      }
      if (a.category > b.category) {
        return 1;
      }
      if (a.subCategory < b.subCategory) {
        return -1;
      }
      if (a.subCategory > b.subCategory) {
        return 1;
      }
      if (a.nameFR < b.nameFR) {
        return -1;
      }
      if (a.nameFR > b.nameFR) {
        return 1;
      }
      return 0;
    });
};

function AdminProducts() {
  const user = useTypedSelector((state) => state.app.user);
  const products = useTypedSelector((state) => state.app.products);
  const productsTree = useTypedSelector((state) => state.app.categories);
  const customizations = useTypedSelector((state) => state.app.customizations);
  const [updateProductId, setUpdateProductId] = useState<string | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const [filteredProducts, setFilteredProfucts] = useState(products);

  useEffect(() => {
    setFilteredProfucts(filterProducts(products, search));
  }, [products, search]);

  const fileUploaded = async () => {
    const files = fileInput?.current?.files;
    if (files?.length) {
      const file = files[0];
      if (file.type === 'application/json') {
        try {
          let products: Array<Product> = [];
          const importFile = JSON.parse(await file.text()) as ImportFileModel;
          for (const sc of importFile.subCategories) {
            products = [...products, ...sc.products];
            sc.products = [];
            if (sc.id) {
              console.log('update cat ' + sc.nameFR);
              subCategoriesApi.doc(sc.id).set(sc);
            } else {
              console.log('create cat ' + sc.nameFR);
              subCategoriesApi.add(sc);
            }
          }
          for (const p of products) {
            if (p.uid) {
              console.log('update product ' + p.nameFR);
              productsApi.doc(p.uid).set(p);
            } else {
              console.log('create product ' + p.nameFR);
              productsApi.add(p);
            }
          }
          for (const c of importFile.customizations) {
            if (c.uid) {
              console.log('update customization ' + c.nameFR);
              customizationApi.doc(c.uid).set(c);
            } else {
              console.log('create customization ' + c.nameFR);
              customizationApi.add(c);
            }
          }
        } catch (e) {
          console.warn(e);
        }
      }
    }
  };

  const getSubCategoryName = (subCategoryId: string) => {
    const subCategory = productsTree
      .find((c) => c.subCategories.find((sc) => sc.id === subCategoryId))
      ?.subCategories.find((sc) => sc.id === subCategoryId);
    return subCategory ? subCategory.nameFR : '';
  };

  const downloadConf = () => {
    const conf: ImportFileModel = {
      subCategories: [],
      customizations: customizations,
    };
    productsTree.forEach(
      (c) =>
        (conf.subCategories = conf.subCategories.concat([
          ...c.subCategories.map((sc) => {
            const cp = JSON.parse(JSON.stringify(sc));
            return cp;
          }),
        ]))
    );
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(conf, null, 2)], { type: 'application/json' })
    );
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = 'conf.json';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <div className="Admin">
      {user ? (
        <div className="AdminRoot">
          <input
            id="file-input"
            accept=".json"
            ref={fileInput}
            type="file"
            onChange={fileUploaded}
            className="DisplayNone"
          />
          <div className="addProduct">
            <IconButton
              color="primary"
              aria-label="upload"
              onClick={() => {
                fileInput?.current?.click();
              }}
            >
              <CloudUpload />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="download"
              onClick={downloadConf}
            >
              <CloudDownload />
            </IconButton>
          </div>
          <div className="ProductList">
            <div className="addProduct">
              <IconButton
                color="primary"
                aria-label="add product"
                onClick={() => {
                  setUpdateProductId('create');
                }}
              >
                <Add />
              </IconButton>
            </div>
            <div className="ProductListTitle">
              <TextField
                id="searchProduct"
                label="Recherche"
                variant="outlined"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            {filteredProducts &&
              filteredProducts.map((product, index) => (
                <React.Fragment key={product.uid}>
                  {index === 0 ||
                  filteredProducts[index - 1].category !== product.category ? (
                    <div className="ProductListCategory">
                      {product.category}
                    </div>
                  ) : null}

                  {index === 0 ||
                  filteredProducts[index - 1].subCategory !==
                    product.subCategory ? (
                    <div className="ProductListSubCategory">
                      {getSubCategoryName(product.subCategory)}
                    </div>
                  ) : null}
                  <div
                    key={index}
                    onClick={() => {
                      setUpdateProductId(product.uid);
                    }}
                    className="ProductListItem"
                  >
                    {product.nameFR}
                  </div>
                </React.Fragment>
              ))}
          </div>
          {updateProductId !== null ? (
            <UpsertProduct
              setUpdateProductId={setUpdateProductId}
              method={updateProductId === 'create' ? 'create' : 'update'}
              product={products.find((p) => p.uid === updateProductId)}
            />
          ) : (
            ''
          )}
        </div>
      ) : (
        'Forbidden'
      )}
    </div>
  );
}

export default AdminProducts;
