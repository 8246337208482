import '../App.css';
import { useTypedSelector } from '../store';
import { useTranslation } from 'react-i18next';

function CustomizationsFront() {
  const { t } = useTranslation();
  const customizations = useTypedSelector((state) => state.app.customizations);
  const language = useTypedSelector((state) => state.app.language);

  return (
    <div className="CustomizationsFront">
      <div className="Title">{t('personnalisation')}</div>
      <div className="Content">
        <div className="CustomizationCategory">
          <div className="Title">{t('Sur mesure')}</div>
          <div className="Content">
            <div className="Items">
              <div className="Item">
                <div className="Name">{t('Pourquoi le sur mesure ?')}</div>
                <div className="Image">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/sellerie-kirin.appspot.com/o/personnalisation-licols-licous%2F0%2F170?alt=media&token=b2ad7395-45f4-4d28-8238-c406c4b686e5"
                    alt={t('Pourquoi le sur mesure ?')}
                  />
                </div>
                <div className="Description">
                  - {t('Pourquoi ?')} <br />-{' '}
                  {t("Danger d'un matériel mal adapté")} <br />-{' '}
                  {t('Comment ?')} <br />- {t('Garanties')} <br />
                </div>
              </div>
              <div className="Item">
                <div className="Name">{t('Fiches de prises de mesures')}</div>
                <div className="Image">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/sellerie-kirin.appspot.com/o/personnalisation-licols-licous%2F0%2F170?alt=media&token=b2ad7395-45f4-4d28-8238-c406c4b686e5"
                    alt={t('Pourquoi le sur mesure ?')}
                  />
                </div>
                <div className="Description">
                  - {t('Chiens (colliers, harnais, porte-médaille)')}
                  <br />- {t('Chevaux (licol, filet, bride, side-pull, etc.)')}
                  <br />- {t('Maroquinerie (ceinture, bracelet, etc. )')}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        {customizations.map((customizationCategory) => (
          <div
            key={customizationCategory.uid}
            className="CustomizationCategory"
          >
            <div className="Title">
              {language === 'fr'
                ? customizationCategory.nameFR
                : customizationCategory.nameEN}
            </div>
            <div className="Content">
              <div className="Description">
                {language === 'fr'
                  ? customizationCategory.descriptionFR
                  : customizationCategory.descriptionEN}
              </div>
              <div className="Items">
                {customizationCategory.items.map((item) => (
                  <div key={item.uid} className="Item">
                    <div className="Name">
                      {language === 'fr' ? item.nameFR : item.nameEN}
                    </div>
                    <div className="Image">
                      <img
                        src={item.images[0]?.thumbnail || '/placeholder.png'}
                        alt={item.nameFR}
                      />
                    </div>
                    <div className="Description">
                      {language === 'fr'
                        ? item.descriptionFR
                        : item.descriptionEN}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomizationsFront;
