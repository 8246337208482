import React from 'react';
import '../App.css';
import { Category, SubCategory } from '../types/product';
import { useTypedSelector } from '../store';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = { category: Category; subCategory: SubCategory };
function SubCategoryElement({ subCategory }: Props) {
  const { t } = useTranslation();
  const language = useTypedSelector((state) => state.app.language);

  return subCategory ? (
    <div className="SubCategoryAlone">
      <div className="Breadcrumb">
        <Link to={`/${subCategory.category}`}>{t(subCategory.category)}</Link>
        <div className="fakeBreadcrumbLink">
          {language === 'fr' ? subCategory.nameFR : subCategory.nameEN}
        </div>
      </div>
      <div className="Title">
        {language === 'fr' ? subCategory.nameFR : subCategory.nameEN}
      </div>
      <div className="Content">
        <div className="ScDescription">
          {language === 'fr'
            ? subCategory.descriptionFR
            : subCategory.descriptionEN}
        </div>
        <div className="ChooseProduct">
          {subCategory.products &&
            subCategory.products.map((product) => (
              <Link key={product.uid} to={`/product/${product.uid}`}>
                <div className="ProductChoice">
                  <img
                    src={
                      product.images && product.images.length > 0
                        ? product.images[0].thumbnail
                        : '/placeholder.png'
                    }
                    alt={language === 'fr' ? product.nameFR : product.nameEN}
                  />
                  <div className="Name">
                    {language === 'fr' ? product.nameFR : product.nameEN}
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  ) : null;
}

export default SubCategoryElement;
