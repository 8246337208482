import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { Category } from '../types/product';
import { useTypedSelector } from '../store';

type Props = { category: Category };
function CategoryElement({ category }: Props) {
  const language = useTypedSelector((state) => state.app.language);

  return category ? (
    <div className="Category">
      <div className="Title">
        <img
          alt={category.uid}
          src={category.image?.medium || `/categories/${category.uid}.png`}
        />
        <div className="Name">
          {language === 'fr' ? category.nameFR : category.nameEN}
        </div>
        <div className="Hidder"></div>
      </div>

      <div className="SubCategories">
        {category.subCategories &&
          category.subCategories.map((subCategory) => (
            <Link
              key={`/${category.uid}/${subCategory.id}`}
              to={`/${category.uid}/${subCategory.id}`}
            >
              <div className="SubCategoryItem">
                <img
                  alt={subCategory.id}
                  src={subCategory.image?.medium || '/placeholder.png'}
                />
                <div className="Name">
                  {language === 'fr' ? subCategory.nameFR : subCategory.nameEN}
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  ) : null;
}

export default CategoryElement;
