import '../App.css';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../store';

function About() {
  const contact = useTypedSelector((state) => state.app.contact);
  const { t } = useTranslation();

  return (
    <div className="About">
      <div className="banner">
        <img alt="about-banner" src="/about-banner-2.png" />
        <div className="Title">{t('about-title')}</div>
      </div>
      <div className="Content">
        <div className="left">
          <div className="Text">{t('about-text')}</div>
          <div className="Signature">
            {contact.firstname} {contact.lastname} {t('about-signature')}
          </div>
        </div>
        <div className="Image">
          <img alt="about" src="/about.jpg" />
        </div>
      </div>
    </div>
  );
}

export default About;
