import React from 'react';
import '../App.css';
import LanguageSelect from './LanguageSelect';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import { useTypedSelector } from '../store';
import { auth, signInWithGoogle } from '../firebase';
import { IconButton } from '@material-ui/core';
import { Lock, Settings } from '@material-ui/icons';
import useWindowDimensions from '../useWindowDimensions';
import { useHistory } from 'react-router-dom';
import MaterialUiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

function Header() {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const user = useTypedSelector((state) => state.app.user);
  const toggleLogin = () => {
    if (user) {
      auth.signOut();
    } else {
      signInWithGoogle();
    }
  };
  const redirectToAdmin = (
    subPage: 'products' | 'subcategories' | 'customizations' | 'categories'
  ) => {
    if (user) {
      switch (subPage) {
        case 'products':
          history.push('/admin-products');
          break;
        case 'subcategories':
          history.push('/admin-subcategories');
          break;
        case 'customizations':
          history.push('/admin-customizations');
          break;
        case 'categories':
          history.push('/admin-categories');
          break;
      }
    }
    handleClose();
  };
  const redirectToHome = () => {
    history.push('');
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className="Header">
      <div className="header-left"></div>
      <div className="header-right"></div>
      <div className="header-l1">
        <div className="Logo" onClick={redirectToHome}>
          <img src="/LogoHeader.svg" alt="logo" />
        </div>
        {width > 1366 ? <Menu /> : <MobileMenu />}
      </div>
      <LanguageSelect />

      <div className="login">
        <IconButton color="primary" aria-label="login" onClick={toggleLogin}>
          <Lock />
        </IconButton>
        {user ? (
          <span>
            <IconButton
              color="primary"
              aria-label="admin"
              aria-controls="admin-menu"
              aria-haspopup="true"
              onClick={openMenu}
            >
              <Settings />
            </IconButton>
            <MaterialUiMenu
              id="admin-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  redirectToAdmin('products');
                }}
              >
                Produits
              </MenuItem>
              <MenuItem
                onClick={() => {
                  redirectToAdmin('subcategories');
                }}
              >
                Sous-categories
              </MenuItem>
              <MenuItem
                onClick={() => {
                  redirectToAdmin('categories');
                }}
              >
                Categories
              </MenuItem>
              <MenuItem
                onClick={() => {
                  redirectToAdmin('customizations');
                }}
              >
                Personnalisations
              </MenuItem>
            </MaterialUiMenu>
          </span>
        ) : null}
      </div>
    </header>
  );
}

export default Header;
