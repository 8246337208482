import '../App.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTypedSelector } from '../store';

function Menu() {
  const categories = useTypedSelector((state) => state.app.categories);

  const language = useTypedSelector((state) => state.app.language);
  const user = useTypedSelector((state) => state.app.user);

  const harnachement = categories.find((c) => c.uid === 'harnachement');
  const canin = categories.find((c) => c.uid === 'canin');
  const maroquinerie = categories.find((c) => c.uid === 'maroquinerie');
  const stock = categories.find((c) => c.uid === 'stock');

  const harnachementName = () => {
    if (harnachement) {
      return language === 'fr' ? harnachement?.nameFR : harnachement?.nameEN;
    }
    return t('harnachement');
  };
  const caninName = () => {
    if (canin) {
      return language === 'fr' ? canin?.nameFR : canin?.nameEN;
    }
    return t('canin');
  };
  const maroquinerieName = () => {
    if (maroquinerie) {
      return language === 'fr' ? maroquinerie?.nameFR : maroquinerie?.nameEN;
    }
    return t('maroquinerie');
  };
  const stockName = () => {
    if (stock) {
      return language === 'fr' ? stock?.nameFR : stock?.nameEN;
    }
    return t('stock');
  };

  const { t } = useTranslation();
  return (
    <div className="Menu">
      <div className="MenuItem">
        <Link to="/harnachement">{harnachementName()}</Link>
      </div>
      <div className="MenuItem">
        <Link to="/canin">{caninName()}</Link>
      </div>
      <div className="MenuItem">
        <Link to="/maroquinerie">{maroquinerieName()}</Link>
      </div>
      {user?.uid ? (
        <div className="MenuItem">
          <Link to="/personnalisation">{t('personnalisation')}</Link>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <div className="MenuItem">
        <Link to="/reparation">{t('reparation')}</Link>
      </div>
      <div className="MenuItem">
        <Link to="/stock">{stockName()}</Link>
      </div>
      <div className="MenuItem">
        <Link to="/about">{t('about')}</Link>
      </div>
      <div className="MenuItem">
        <Link to="/contact">{t('contact')}</Link>
      </div>
    </div>
  );
}

export default Menu;
