import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import '../App.css';
import { Image } from '../types/image';

const zoomId = Symbol('zoom');

interface ImageProps {
  image: Image;
  alt: string;
  zoomWidth?: string;
  zoomHeight?: string;
}

function ImageZoom({
  image,
  alt,
  zoomHeight = '80%',
  zoomWidth = 'auto',
}: ImageProps) {
  const [zoomState, setZoomState] = useState(false);
  const [modalElement, setModalElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    let z = document.getElementById(zoomId.toString());
    if (!z) {
      z = document.createElement('div');
      z.id = zoomId.toString();
      document.body.appendChild(z);
    }
    setModalElement(z);
    if (modalElement) {
      ReactDOM.render(
        <div
          onClick={() => {
            setZoomState(!zoomState);
          }}
          className={`zoom-container ${zoomState ? 'visible' : ''}`}
        >
          {zoomState && <img src={image?.zoom} alt={alt} />}
        </div>,
        modalElement
      );
    }

    return () => {
      if (modalElement) {
        // document.removeChild(modalElement);
      }
    };
  }, [image, alt, zoomState, modalElement]);
  return (
    <div className="ImageZoom">
      <img
        className="ImageZoomThumbnail"
        src={image?.medium || image?.zoom}
        alt={alt}
        onClick={() => {
          setZoomState(!zoomState);
        }}
      />
    </div>
  );
}

export default ImageZoom;
