import React, { ChangeEvent, useState } from 'react';
import '../../../App.css';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
} from 'react-beautiful-dnd';
import { Image } from '../../../types/image';

import { TextField, IconButton } from '@material-ui/core';
import { customizationApi } from '../../../firebase';

import ImageUpload from './ImageUpload';
import { Delete } from '@material-ui/icons';
import { customizationItem } from '../../../types/customization';

interface UpsertCustomizationCategoryProps {
  item?: customizationItem;
  updateItem: (
    property:
      | 'nameFR'
      | 'nameEN'
      | 'descriptionFR'
      | 'descriptionEN'
      | 'images',
    value: string | Image[]
  ) => void;
}

const grid = 8;
const getListStyle = (_isDraggingOver: boolean) => ({
  // background: _isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});
const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? '#F0F0F0' : '#A0A0A0',

  // styles we need to apply on draggables
  ...((draggableStyle as any) || {}),
});

function reorder<T>(list: Array<T>, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function UpsertCustomizationCategoryItem({
  item,
  updateItem,
}: UpsertCustomizationCategoryProps) {
  const [nameFR, setNameFR] = useState(item?.nameFR || '');
  const [nameEN, setNameEN] = useState(item?.nameEN || '');
  const [descriptionFR, setDescriptionFR] = useState(item?.descriptionFR || '');
  const [descriptionEN, setDescriptionEN] = useState(item?.descriptionEN || '');
  const [images, setImages] = useState(item?.images || []);
  const [uid, setUid] = useState(item?.uid || '');

  React.useEffect(() => {
    setNameFR(item?.nameFR || '');
    setNameEN(item?.nameEN || '');
    setDescriptionFR(item?.descriptionFR || '');
    setDescriptionEN(item?.descriptionEN || '');
    setImages(item?.images || []);
    setUid(item?.uid || '');
  }, [item]);

  const handleValueChanged = (
    property: 'nameFR' | 'nameEN' | 'descriptionFR' | 'descriptionEN',
    { target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    updateItem(property, target.value);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder<Image>(
      images,
      result.source.index,
      result.destination.index
    );
    updateItem('images', items);
  };

  const handleDelete = async () => {
    if (uid) {
      await customizationApi.doc(uid).delete();
    }
    dispatchEvent(new CustomEvent('delete', { detail: { value: uid } }));
  };

  const imageChanged = (index: number, newImage: Array<string>) => {
    const tImg = [...images];
    tImg[index] = {
      thumbnail: newImage[0],
      medium: newImage[1],
      zoom: newImage[2],
    };
    updateItem('images', tImg);
  };

  return (
    <div className="CustomCategoryItem">
      <div className="actions">
        <IconButton color="primary" aria-label="delete" onClick={handleDelete}>
          <Delete />
        </IconButton>
      </div>
      <div>
        <div className="NameInput">
          <TextField
            id="nameFR"
            label="Nom FR"
            variant="outlined"
            fullWidth
            value={nameFR}
            onChange={(e) => {
              handleValueChanged('nameFR', e);
            }}
          />
        </div>
        <div className="NameInput">
          <TextField
            id="nameEN"
            label="Nom EN"
            variant="outlined"
            fullWidth
            value={nameEN}
            onChange={(e) => {
              handleValueChanged('nameEN', e);
            }}
          />
        </div>

        <div className="DescriptionInput">
          <TextField
            id="descriptionFR"
            label="Description FR"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={descriptionFR}
            onChange={(e) => {
              handleValueChanged('descriptionFR', e);
            }}
          />
        </div>
        <div className="DescriptionInput">
          <TextField
            id="descriptionEN"
            label="Description EN"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={descriptionEN}
            onChange={(e) => {
              handleValueChanged('descriptionEN', e);
            }}
          />
        </div>

        {uid ? (
          <div className="imagesInput">
            {!images[0]?.thumbnail ? (
              <span className="AddImageLabel">Ajouter une image</span>
            ) : null}
            <ImageUpload
              image={{ thumbnail: '', medium: '', zoom: '' }}
              index={images.length}
              productUID={uid}
              sizes={[175, 300, 1440]}
              onImageChanged={imageChanged}
            ></ImageUpload>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {images.map((image, index) => (
                      <Draggable
                        key={image.thumbnail}
                        draggableId={image.thumbnail}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <ImageUpload
                              image={image}
                              index={index}
                              key={index}
                              productUID={uid}
                              sizes={[175, 300, 1440]}
                              onImageChanged={imageChanged}
                            ></ImageUpload>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <div>Enregistrez la catégorie pour ajouter des images</div>
        )}
      </div>
    </div>
  );
}

export default UpsertCustomizationCategoryItem;
