const config = {
  apiKey: 'AIzaSyAVuqAtTji7tTpL7iBTFzjzighFY3bHhxE',
  authDomain: 'sellerie-kirin.firebaseapp.com',
  projectId: 'sellerie-kirin',
  storageBucket: 'sellerie-kirin.appspot.com',
  messagingSenderId: '195693903950',
  appId: '1:195693903950:web:1b5125ccc982ed340a431f',
  databaseURL:
    'https://sellerie-kirin-default-rtdb.europe-west1.firebasedatabase.app',
};
export default config;
