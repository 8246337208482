import '../App.css';
import { useTranslation } from 'react-i18next';

function Repairs() {
  const { t } = useTranslation();

  return (
    <div className="Repairs">
      <div className="banner">
        <img alt="about-banner" src="/repairs-banner.png" />
        <div className="Title">{t('repairs-title')}</div>
      </div>
      <div className="Content">
        <div className="left">
          <div className="Text">{t('repairs-text')}</div>
          <div className="Image">
            <img alt="repair-3" src="/repair-3.jpg" />
            <div className="Legend">{t('repairs-legend-1')}</div>
          </div>
        </div>
        <div className="Image">
          <img alt="repair-1" src="/repair-1.jpg" />
          <img alt="repair-2" src="/repair-2.jpg" />
          <div className="Legend">{t('repairs-legend-2')}</div>
        </div>
      </div>
    </div>
  );
}

export default Repairs;
