import '../App.css';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../store';

function HowToOrder() {
  const { t } = useTranslation();
  const contact = useTypedSelector((state) => state.app.contact);
  const mailto = `mailto: ${contact.email}`;
  return (
    <div className="HowToOrder">
      <div className="Title">{t('howToOrder-title')}</div>
      <div className="Content">
        <div className="Step">
          <div className="Title">{t('howToOrder-title1')}</div>
          <div className="Text">
            <div>
              - {t('via')} <a href="/contact">{t('howToOrder-site-form')}</a>
            </div>
            <div>
              - {t('via')}{' '}
              <a href="https://www.facebook.com/selleriekirin/">
                {t('howToOrder-facebook')}
              </a>
            </div>
            <div>
              - {t('via')} <a href={mailto}>{t('howToOrder-email')}</a>
            </div>
            <div>
              - {t('howToOrder-telephone')} {contact.telephone}
            </div>
          </div>
          <div className="Number">1</div>
        </div>
        <div className="Step">
          <div className="Title">{t('howToOrder-title2')}</div>
          <div className="Text">
            <div>- {t('howToOrder-carac')}</div>
            <div>- {t('howToOrder-custom')}</div>
            <div>- {t('howToOrder-answer')}</div>
            <div>- {t('howToOrder-devis')}</div>
          </div>
          <div className="Number">2</div>
        </div>
        <div className="Step">
          <div className="Title">{t('howToOrder-title3')}</div>
          <div className="Text">
            <div>-{t('howToOrder-text3-a')}</div>
            <div>-{t('howToOrder-text3-b')}</div>
          </div>
          <div className="Number">3</div>
        </div>
        <div className="Step">
          <div className="Title">{t('howToOrder-title4')}</div>
          <div className="Text">{t('howToOrder-text4')}</div>
          <div className="Number">4</div>
        </div>
        <div className="Step">
          <div className="Title">{t('howToOrder-title5')}</div>
          <div className="Text">
            <div>-{t('howToOrder-text5-a')}</div>
            <div>-{t('howToOrder-text5-b')}</div>
          </div>
          <div className="Number">5</div>
        </div>
      </div>
      <div className="CGV">
        {t('howToOrder-think-read')} <a href="/CGV">{t('CGV')}</a>
      </div>
    </div>
  );
}

export default HowToOrder;
