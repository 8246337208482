import React, { ChangeEvent, useState } from 'react';
import '../../../App.css';
import { SubCategory } from '../../../types/product';
import { TextField, MenuItem, IconButton } from '@material-ui/core';
import {
  getProducts,
  getSubCategories,
  subCategoriesApi,
} from '../../../firebase';
import {
  getCategories,
  setProducts,
  setSubCategories,
} from '../../../reducers/AppReducer';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from '../../../store';
import { Delete, Save } from '@material-ui/icons';
import ImageUpload from './ImageUpload';

interface UpsertProductProps {
  subCategory?: SubCategory;
  method: 'update' | 'create';
  setCategoryId: (id: string | null) => void;
}

function UpsertSubCategory({
  subCategory,
  method,
  setCategoryId,
}: UpsertProductProps) {
  const [nameFR, setNameFR] = useState(subCategory?.nameFR || '');
  const [nameEN, setNameEN] = useState(subCategory?.nameEN || '');
  const [category, setCategory] = useState(subCategory?.category || '');
  const [descriptionFR, setDescriptionFR] = useState(
    subCategory?.descriptionFR || ''
  );
  const [descriptionEN, setDescriptionEN] = useState(
    subCategory?.descriptionEN || ''
  );
  const [id, setId] = useState(subCategory?.id || null);
  const [image, setImage] = useState(
    subCategory?.image || { thumbnail: '', medium: '', zoom: '' }
  );

  const categories = getCategories(store.getState());
  const { t } = useTranslation();

  const dispatch = useDispatch();

  React.useEffect(() => {
    setNameFR(subCategory?.nameFR || '');
    setNameEN(subCategory?.nameEN || '');
    setCategory(subCategory?.category || '');
    setDescriptionFR(subCategory?.descriptionFR || '');
    setDescriptionEN(subCategory?.descriptionEN || '');
    setId(subCategory?.id || null);
    setImage(subCategory?.image || { thumbnail: '', medium: '', zoom: '' });
  }, [subCategory]);

  const handleValueChanged = (
    property: string,
    { target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    switch (property) {
      case 'nameFR':
        return setNameFR(target.value);
      case 'nameEN':
        return setNameEN(target.value);
      case 'category':
        return setCategory(target.value);
      case 'descriptionFR':
        return setDescriptionFR(target.value);
      case 'descriptionEN':
        return setDescriptionEN(target.value);
      case 'componentsFR':
    }
  };

  const imageChanged = (_index: number, changedImage: Array<string>) => {
    return setImage({ thumbnail: '', medium: changedImage[0], zoom: '' });
  };

  const handleDelete = async () => {
    if (method !== 'create' && id) {
      await subCategoriesApi.doc(id).delete();
    }
    const subCategories = await getSubCategories();
    await dispatch(setSubCategories(subCategories));
    const products = await getProducts();
    dispatch(setProducts(products));
    setCategoryId(null);
  };

  const handleSave = async () => {
    const body = {
      nameFR,
      nameEN,
      category,
      descriptionFR,
      descriptionEN,
      image,
    };
    let createdId: string | null = null;
    if (method === 'create') {
      const document = await subCategoriesApi.add(body);
      createdId = document.id;
    } else if (id) {
      await subCategoriesApi.doc(id).set(body);
    }
    const subCategories = await getSubCategories();
    await dispatch(setSubCategories(subCategories));
    const products = await getProducts();
    await dispatch(setProducts(products));
    if (createdId) {
      setCategoryId(createdId);
    }
  };

  return true ? (
    <div className="UpsertProduct">
      <div className="title">
        <div className="content">
          {method === 'create'
            ? 'Créer une sous catégorie'
            : `Modifier la sous catégorie "${nameFR}"`}
        </div>
        <div className="actions">
          <IconButton color="primary" aria-label="save" onClick={handleSave}>
            <Save />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="add product"
            onClick={handleDelete}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
      <div>
        <div className="Flex Horizontal SpaceBetween">
          <div className="NameInput">
            <TextField
              id="nameFR"
              label="Nom FR"
              variant="outlined"
              fullWidth
              value={nameFR}
              onChange={(e) => {
                handleValueChanged('nameFR', e);
              }}
            />
          </div>
          <div className="NameInput">
            <TextField
              id="nameEN"
              label="Nom EN"
              variant="outlined"
              fullWidth
              value={nameEN}
              onChange={(e) => {
                handleValueChanged('nameEN', e);
              }}
            />
          </div>
        </div>
        <div className="Flex Horizontal SpaceBetween">
          <div className="SelectInput">
            <TextField
              id="category"
              select
              fullWidth
              label="Categorie"
              value={category}
              onChange={(e) => {
                handleValueChanged('category', e);
              }}
              variant="outlined"
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {t(`${category}`)}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <div className="DescriptionInput">
          <TextField
            id="descriptionFR"
            label="Description FR"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={descriptionFR}
            onChange={(e) => {
              handleValueChanged('descriptionFR', e);
            }}
          />
        </div>
        <div className="DescriptionInput">
          <TextField
            id="descriptionEN"
            label="Description EN"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={descriptionEN}
            onChange={(e) => {
              handleValueChanged('descriptionEN', e);
            }}
          />
        </div>
        Image:
        {id ? (
          <div className="imagesInput">
            <ImageUpload
              image={image}
              index={0}
              productUID={id}
              sizes={[170]}
              onImageChanged={imageChanged}
            ></ImageUpload>
          </div>
        ) : (
          <div>Enregistrez le produit pour ajouter des images</div>
        )}
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default UpsertSubCategory;
