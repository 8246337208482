import { useState } from 'react';
import '../../App.css';
import { useTypedSelector } from '../../store';
import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import UpsertSubCategory from './components/UpsertSubCategory';

function AdminSubCategories() {
  const user = useTypedSelector((state) => state.app.user);
  const productsTree = useTypedSelector((state) => state.app.categories);
  const [categoryId, setCategoryId] = useState<null | string>(null);

  const getSubCategory = (id: string) => {
    let sc = null;
    if (id !== 'create' && id) {
      let i = 0;
      while (sc === null && productsTree[i]) {
        sc =
          productsTree[i].subCategories.find((subCat) => subCat.id === id) ||
          null;
        i++;
      }
    }
    return sc || undefined;
  };

  return (
    <div className="Admin">
      {user ? (
        <div className="AdminRoot">
          <div className="ProductList">
            <div className="addProduct">
              <IconButton
                color="primary"
                aria-label="add sub category"
                onClick={() => {
                  setCategoryId('create');
                }}
              >
                <Add />
              </IconButton>
            </div>
            {productsTree &&
              productsTree.map((category) => (
                <div className="category" key={category.uid}>
                  <div className="name">{category.nameFR}</div>
                  <div className="subCategories">
                    {category.subCategories.map((subCategory, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setCategoryId(subCategory.id);
                        }}
                        className="ProductListItem"
                      >
                        {subCategory.nameFR}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          {categoryId !== null ? (
            <UpsertSubCategory
              setCategoryId={setCategoryId}
              method={categoryId === 'create' ? 'create' : 'update'}
              subCategory={getSubCategory(categoryId)}
            />
          ) : (
            ''
          )}
        </div>
      ) : (
        'Forbidden'
      )}
    </div>
  );
}

export default AdminSubCategories;
