import React, { ChangeEvent, useState } from 'react';
import '../../../App.css';

import { TextField, IconButton, Button } from '@material-ui/core';
import { customizationApi, getCustomizations } from '../../../firebase';

import ImageUpload from './ImageUpload';
import { Image } from '../../../types/image';
import { Delete, Save } from '@material-ui/icons';
import { customizationCategory } from '../../../types/customization';
import UpsertCustomizationCategoryItem from './UpsertCustomizationCategoryItem';
import { setCustomizations } from '../../../reducers/AppReducer';
import { useDispatch } from 'react-redux';

interface UpsertCustomizationCategoryProps {
  customizationCategory?: customizationCategory;
}

function UpsertCustomizationCategory({
  customizationCategory,
}: UpsertCustomizationCategoryProps) {
  const [nameFR, setNameFR] = useState(customizationCategory?.nameFR || '');
  const [nameEN, setNameEN] = useState(customizationCategory?.nameEN || '');
  const [descriptionFR, setDescriptionFR] = useState(
    customizationCategory?.descriptionFR || ''
  );
  const [descriptionEN, setDescriptionEN] = useState(
    customizationCategory?.descriptionEN || ''
  );
  const [image, setImage] = useState(
    customizationCategory?.image || {
      thumbnail: '',
      medium: '',
      zoom: '',
    }
  );
  const [uid, setUid] = useState(customizationCategory?.uid || null);
  const [items, setItems] = useState(customizationCategory?.items || []);

  const dispatch = useDispatch();

  const addItem = () => {
    setItems([
      ...items,
      {
        uid: new Date().getTime().toString(),
        nameFR: 'Nouvelle personnalisation',
        nameEN: 'New customization',
        descriptionFR: '',
        descriptionEN: '',
        images: [],
      },
    ]);
  };

  React.useEffect(() => {
    setNameFR(customizationCategory?.nameFR || '');
    setNameEN(customizationCategory?.nameEN || '');
    setDescriptionFR(customizationCategory?.descriptionFR || '');
    setDescriptionEN(customizationCategory?.descriptionEN || '');
    setImage(
      customizationCategory?.image || {
        thumbnail: '',
        medium: '',
        zoom: '',
      }
    );
    setItems(customizationCategory?.items || []);
    setUid(customizationCategory?.uid || null);
  }, [customizationCategory]);

  const handleValueChanged = (
    property: string,
    { target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    switch (property) {
      case 'nameFR':
        return setNameFR(target.value);
      case 'nameEN':
        return setNameEN(target.value);
      case 'descriptionFR':
        return setDescriptionFR(target.value);
      case 'descriptionEN':
        return setDescriptionEN(target.value);
    }
  };

  const handleDelete = async () => {
    if (uid) {
      await customizationApi.doc(uid).delete();
    }
    const customizations = await getCustomizations();
    dispatch(setCustomizations(customizations));
  };

  const updateItem = (
    index: number,
    property:
      | 'nameFR'
      | 'nameEN'
      | 'descriptionFR'
      | 'descriptionEN'
      | 'images',
    value: string | Image[]
  ) => {
    const tItems = [...items];
    const tItem = { ...tItems[index] };
    if (property === 'images') {
      if (value instanceof Array) {
        tItem[property] = value;
      }
    } else {
      if (typeof value === 'string') {
        tItem[property] = value;
      }
    }
    tItems[index] = tItem;
    setItems(tItems);
  };

  const handleSave = async () => {
    const body = {
      nameFR,
      nameEN,
      items,
      image,
      descriptionFR,
      descriptionEN,
    };
    if (!uid) {
      await customizationApi.add(body);
    } else if (uid) {
      await customizationApi.doc(uid).set(body);
    }
    const customizations = await getCustomizations();
    dispatch(setCustomizations(customizations));
  };

  const imageChanged = (_index: unknown, newImage: Array<string>) => {
    const tmp = {
      thumbnail: newImage[0],
      medium: newImage[1],
      zoom: newImage[2],
    };
    return setImage(tmp);
  };

  return (
    <div className="UpsertProduct">
      <div className="title">
        <div className="content">
          {uid
            ? 'Créer une catégorie de personnalisation'
            : `Modifier "${nameFR}"`}
        </div>
        <div className="actions">
          <IconButton color="primary" aria-label="save" onClick={handleSave}>
            <Save />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="add product"
            onClick={handleDelete}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
      <div>
        <div className="Flex Horizontal SpaceBetween">
          <div className="NameInput">
            <TextField
              id="nameFR"
              label="Nom FR"
              variant="outlined"
              fullWidth
              value={nameFR}
              onChange={(e) => {
                handleValueChanged('nameFR', e);
              }}
            />
          </div>
          <div className="NameInput">
            <TextField
              id="nameEN"
              label="Nom EN"
              variant="outlined"
              fullWidth
              value={nameEN}
              onChange={(e) => {
                handleValueChanged('nameEN', e);
              }}
            />
          </div>
        </div>

        <div className="DescriptionInput">
          <TextField
            id="descriptionFR"
            label="Description FR"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={descriptionFR}
            onChange={(e) => {
              handleValueChanged('descriptionFR', e);
            }}
          />
        </div>
        <div className="DescriptionInput">
          <TextField
            id="descriptionEN"
            label="Description EN"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={descriptionEN}
            onChange={(e) => {
              handleValueChanged('descriptionEN', e);
            }}
          />
        </div>

        {uid ? (
          <div className="imagesInput">
            {!image.thumbnail ? (
              <span className="AddImageLabel">Ajouter une image</span>
            ) : null}
            <ImageUpload
              image={image}
              index={0}
              key={0}
              productUID={uid}
              sizes={[175, 300, 1440]}
              onImageChanged={imageChanged}
            ></ImageUpload>
          </div>
        ) : (
          <div>Enregistrez la catégorie pour ajouter des images</div>
        )}
        {uid ? (
          <div className="CustomizationCategoryItemsContainer">
            <div className="CustomizationCategoryItemsTitle">
              Personnalisations:
            </div>
            <div className="CustomizationCategoryItems">
              {items.map((item, index) => (
                <div className="CustomizationCategoryItem" key={index}>
                  <UpsertCustomizationCategoryItem
                    item={item}
                    updateItem={(
                      property:
                        | 'nameFR'
                        | 'nameEN'
                        | 'descriptionFR'
                        | 'descriptionEN'
                        | 'images',
                      value: string | Image[]
                    ) => {
                      updateItem(index, property, value);
                    }}
                  />
                </div>
              ))}
            </div>
            <Button onClick={addItem} variant="outlined">
              Ajouter une personnalisation
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default UpsertCustomizationCategory;
