import { configureStore } from '@reduxjs/toolkit';
import appReducer, {
  State as AppState,
  stateName as appStateName,
} from './reducers/AppReducer';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

export interface RootState {
  [appStateName]: AppState;
}

declare global {
  interface Window {
    reduxStore: any;
  }
}

window.reduxStore = configureStore({
  reducer: {
    [appStateName]: appReducer,
  },
});

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default window.reduxStore;
