import React, { ChangeEvent, useState } from 'react';
import '../../../App.css';
import { Category } from '../../../types/product';
import { TextField, IconButton } from '@material-ui/core';
import { categoriesApi, getCategories } from '../../../firebase';
import { setCategories } from '../../../reducers/AppReducer';
import { useDispatch } from 'react-redux';
import { Save } from '@material-ui/icons';
import ImageUpload from './ImageUpload';

interface UpsertProductProps {
  category?: Category;
}

function UpsertCategory({ category }: UpsertProductProps) {
  const [nameFR, setNameFR] = useState(category?.nameFR || '');
  const [nameEN, setNameEN] = useState(category?.nameEN || '');
  const [image, setImage] = useState(
    category?.image || { thumbnail: '', medium: '', zoom: '' }
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    setNameFR(category?.nameFR || '');
    setNameEN(category?.nameEN || '');
    setImage(category?.image || { thumbnail: '', medium: '', zoom: '' });
  }, [category]);

  const handleValueChanged = (
    property: string,
    { target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    switch (property) {
      case 'nameFR':
        return setNameFR(target.value);
      case 'nameEN':
        return setNameEN(target.value);
    }
  };

  const imageChanged = (_index: number, changedImage: Array<string>) => {
    return setImage({ thumbnail: '', medium: changedImage[0], zoom: '' });
  };

  const handleSave = async () => {
    const body = {
      nameFR,
      nameEN,
      image,
    };
    if (category) {
      await categoriesApi.doc(category.uid).set(body);
      const categories = await getCategories();
      await dispatch(setCategories(categories));
    }
  };

  return true ? (
    <div className="UpsertProduct">
      <div className="title">
        <div className="content">Modifier la sous catégorie "{nameFR}"</div>
        <div className="actions">
          <IconButton color="primary" aria-label="save" onClick={handleSave}>
            <Save />
          </IconButton>
        </div>
      </div>
      <div>
        <div className="Flex Horizontal SpaceBetween">
          <div className="NameInput">
            <TextField
              id="nameFR"
              label="Nom FR"
              variant="outlined"
              fullWidth
              value={nameFR}
              onChange={(e) => {
                handleValueChanged('nameFR', e);
              }}
            />
          </div>
          <div className="NameInput">
            <TextField
              id="nameEN"
              label="Nom EN"
              variant="outlined"
              fullWidth
              value={nameEN}
              onChange={(e) => {
                handleValueChanged('nameEN', e);
              }}
            />
          </div>
        </div>
        Image:
        {category?.uid ? (
          <div className="imagesInput">
            <ImageUpload
              image={image}
              index={0}
              productUID={category.uid}
              sizes={[170]}
              onImageChanged={imageChanged}
            ></ImageUpload>
          </div>
        ) : (
          <div>Enregistrez le produit pour ajouter des images</div>
        )}
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default UpsertCategory;
