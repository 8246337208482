import { useState } from 'react';
import '../../App.css';
import { useTypedSelector } from '../../store';
import UpsertCategory from './components/UpsertCategory';
import { Category } from '../../types/product';

function AdminCategories() {
  const user = useTypedSelector((state) => state.app.user);
  const productsTree = useTypedSelector((state) => state.app.categories);
  const [selectedCategory, setSelectedCategory] = useState<null | Category>(
    null
  );

  return (
    <div className="Admin">
      {user ? (
        <div className="AdminRoot">
          <div className="CategoryList">
            {productsTree &&
              productsTree.map((category) => (
                <div className="category" key={category.uid}>
                  <div
                    className="name"
                    onClick={() => {
                      setSelectedCategory(category);
                    }}
                  >
                    {category.nameFR}
                  </div>
                </div>
              ))}
          </div>
          {selectedCategory !== null ? (
            <UpsertCategory category={selectedCategory} />
          ) : (
            ''
          )}
        </div>
      ) : (
        'Forbidden'
      )}
    </div>
  );
}

export default AdminCategories;
