import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types/user';
import { Category, Product, SubCategory } from '../types/product';

import { createSelector } from 'reselect';
import { RootState } from '../store';
import { customizationCategory } from '../types/customization';

export const stateName = 'app' as const;
export type StateName = typeof stateName;

const categories: Array<Category> = [];

export interface State {
  user: User | null;
  contact: {
    firstname: string;
    lastname: string;
    telephone: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    zipCode: string;
  };
  language: 'fr' | 'en';
  products: Array<Product>;
  subCategories: Array<SubCategory>;
  categories: Array<Category>;
  customizations: Array<customizationCategory>;
}

export const AppReducer = createSlice({
  name: stateName,
  initialState: {
    language: 'fr',
    user: null,
    products: [],
    subCategories: [],
    categories: categories,
    customizations: [],
    contact: {
      firstname: 'Pauline',
      lastname: 'LAROCHE',
      telephone: '06.29.84.49.59',
      email: 'contact@sellerie-kirin.fr',
      address1: '490 route de bastier',
      address2: 'Saint Julien Labrousse',
      city: 'Belsentes',
      zipCode: '07160',
    },
  } as State,
  reducers: {
    setUser: (state, action: PayloadAction<State['user']>) => {
      state.user = action.payload;
    },
    setLanguage: (state, action: PayloadAction<State['language']>) => {
      state.language = action.payload;
    },
    setProducts: (state, action: PayloadAction<State['products']>) => {
      state.products = action.payload;
      const categories: Array<Category> = state.categories;
      for (const c of state.categories) {
        for (const sc of c.subCategories) {
          sc.products = [];
        }
      }
      for (const p of action.payload) {
        const category = categories.find((c) => c.uid === p.category);
        if (category) {
          const subCategory = category.subCategories.find(
            (sc) => sc.id === p.subCategory
          );
          if (subCategory) {
            subCategory.products.push(p);
          }
        }
      }
      state.categories = categories;
    },
    setCustomizations: (
      state,
      action: PayloadAction<State['customizations']>
    ) => {
      state.customizations = action.payload;
    },
    setSubCategories: (state, action: PayloadAction<Array<SubCategory>>) => {
      const categories: Array<Category> = state.categories;
      state.subCategories = action.payload;
      for (const c of state.categories) {
        c.subCategories = [];
      }
      action.payload.forEach((sc) => {
        const category = categories.find((c) => c.uid === sc.category);
        if (category) {
          category.subCategories.push(sc);
        }
      });
      state.categories = categories;
    },
    setCategories: (state, action: PayloadAction<Array<Category>>) => {
      const newCategories: Array<Category> = [];
      action.payload.forEach((categoryToUpdate) => {
        const category = state.categories.find(
          (c) => c.uid === categoryToUpdate.uid
        );
        if (category) {
          categoryToUpdate.subCategories = category.subCategories || [];
        }
        newCategories.push(categoryToUpdate);
      });
      state.categories = newCategories;
    },
  },
});

const getProductsSelector = (state: RootState) => state.app.categories;

export const getCategories = createSelector(
  [getProductsSelector],
  (categories) => categories.map((c) => c.uid)
);

// Action creators are generated for each case reducer function
export const {
  setUser,
  setProducts,
  setSubCategories,
  setCategories,
  setCustomizations,
  setLanguage,
} = AppReducer.actions;

export default AppReducer.reducer;
