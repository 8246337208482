import React from 'react';
import { useDispatch } from 'react-redux';
import '../App.css';
import i18n from '../i18n';
import { setLanguage } from '../reducers/AppReducer';

interface Props {
  name: 'fr' | 'en';
  icon: string;
}

function LanguageChoice({ name, icon }: Props) {
  const dispatch = useDispatch();

  const changeLanguage = () => {
    dispatch(setLanguage(name));
    i18n.changeLanguage(name);
  };
  return (
    <div className="LanguageChoice">
      <img src={icon} alt={name} onClick={() => changeLanguage()} />
    </div>
  );
}

export default LanguageChoice;
