import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import config from './firebase.config';
import store from './store';
import { setUser } from './reducers/AppReducer';
import { Product, SubCategory, Category } from './types/product';
import { customizationCategory } from './types/customization';

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const customizationApi = firebase
  .firestore()
  .collection('/customizations');
export const categoriesApi = firebase.firestore().collection('/categories');
export const productsApi = firebase.firestore().collection('/products');
export const subCategoriesApi = firebase
  .firestore()
  .collection('/subCategories');
export const usersApi = firebase.firestore().collection('/users');

export const getProducts = async () => {
  const response = await productsApi.get();
  const { docs } = response;
  const products = docs.map((doc) => {
    return { uid: doc.id, ...doc.data() } as Product;
  });
  return products;
};

export const getCustomizations = async () => {
  const response = await customizationApi.get();
  const { docs } = response;
  const customizations = docs.map((doc) => {
    return { uid: doc.id, ...doc.data() } as customizationCategory;
  });
  return customizations;
};

export const getCategories = async () => {
  const response = await categoriesApi.get();
  const { docs } = response;
  const categories = docs.map((doc) => {
    return { uid: doc.id, ...doc.data() } as Category;
  });
  return categories;
};

export const getSubCategories = async () => {
  const response = await subCategoriesApi.get();
  const { docs } = response;
  const subCategories = docs.map((doc) => {
    return { id: doc.id, ...doc.data() } as SubCategory;
  });
  return subCategories;
};

auth.onAuthStateChanged(async (userAuth) => {
  if (userAuth) {
    const { email, displayName, uid } = userAuth;
    const user = await usersApi.doc(uid).get();
    if (user.exists) {
      store.dispatch(setUser({ email, displayName, uid }));
    } else {
      auth.signOut();
    }
  } else {
    store.dispatch(setUser(null));
  }
});

export const storage = firebase.storage();
