import React, { ChangeEvent, useState } from 'react';
import '../../../App.css';
import { useTypedSelector } from '../../../store';
import { Product } from '../../../types/product';
import { Image } from '../../../types/image';
import { TextField, MenuItem, IconButton } from '@material-ui/core';
import { getProducts, productsApi } from '../../../firebase';
import { getCategories, setProducts } from '../../../reducers/AppReducer';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from '../../../store';
import ImageUpload from './ImageUpload';
import { Delete, Save } from '@material-ui/icons';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
} from 'react-beautiful-dnd';

interface UpsertProductProps {
  product?: Product;
  method: 'update' | 'create';
  setUpdateProductId: (id: string | null) => void;
}

const grid = 8;
const getListStyle = (_isDraggingOver: boolean) => ({
  // background: _isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});
const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  background: isDragging ? '#F0F0F0' : '#A0A0A0',

  // styles we need to apply on draggables
  ...((draggableStyle as any) || {}),
});

function reorder<T>(list: Array<T>, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function UpsertProduct({
  product,
  method,
  setUpdateProductId,
}: UpsertProductProps) {
  const language = useTypedSelector((state) => state.app.language);
  const [nameFR, setNameFR] = useState(product?.nameFR || '');
  const [nameEN, setNameEN] = useState(product?.nameEN || '');
  const [componentsFR, setComponentsFR] = useState(product?.componentsFR || '');
  const [componentsEN, setComponentsEN] = useState(product?.componentsEN || '');
  const [category, setCategory] = useState(product?.category || '');
  const [descriptionFR, setDescriptionFR] = useState(
    product?.descriptionFR || ''
  );
  const [descriptionEN, setDescriptionEN] = useState(
    product?.descriptionEN || ''
  );
  const [subCategory, setSubCategory] = useState(product?.subCategory || '');
  const [price, setPrice] = useState(product?.price || '');
  const [images, setImages] = useState(product?.images || []);
  const [uid, setUid] = useState(product?.uid || null);

  const productsTree = useTypedSelector((state) => state.app.categories);
  const categories = getCategories(store.getState());
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder<Image>(
      images,
      result.source.index,
      result.destination.index
    );

    setImages(items);
  };

  React.useEffect(() => {
    setNameFR(product?.nameFR || '');
    setNameEN(product?.nameEN || '');
    setCategory(product?.category || '');
    setDescriptionFR(product?.descriptionFR || '');
    setDescriptionEN(product?.descriptionEN || '');
    setComponentsFR(product?.componentsFR || '');
    setComponentsEN(product?.componentsEN || '');
    setSubCategory(product?.subCategory || '');
    setImages(product?.images || []);
    setPrice(product?.price || '');
    setUid(product?.uid || null);
  }, [product]);

  const handleValueChanged = (
    property: string,
    { target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    switch (property) {
      case 'nameFR':
        return setNameFR(target.value);
      case 'nameEN':
        return setNameEN(target.value);
      case 'category':
        return setCategory(target.value);
      case 'descriptionFR':
        return setDescriptionFR(target.value);
      case 'descriptionEN':
        return setDescriptionEN(target.value);
      case 'componentsFR':
        return setComponentsFR(target.value);
      case 'componentsEN':
        return setComponentsEN(target.value);
      case 'subCategory':
        return setSubCategory(target.value);
      case 'price':
        return setPrice(target.value);
    }
  };

  const handleDelete = async () => {
    if (method !== 'create' && uid) {
      await productsApi.doc(uid).delete();
    }
    const products = await getProducts();
    dispatch(setProducts(products));
    setUpdateProductId(null);
  };

  const handleSave = async () => {
    const body = {
      nameFR,
      nameEN,
      category,
      subCategory,
      descriptionFR,
      descriptionEN,
      componentsFR,
      componentsEN,
      price,
      images: images.filter((v) => v),
    };
    let createdId: string | null = null;
    if (method === 'create') {
      const document = await productsApi.add(body);
      createdId = document.id;
    } else if (uid) {
      await productsApi.doc(uid).set(body);
    }
    const products = await getProducts();
    await dispatch(setProducts(products));
    if (createdId) {
      setUpdateProductId(createdId);
    }
  };

  const getSubCategories = (category: string) => {
    return productsTree.find((c) => c.uid === category)?.subCategories || [];
  };

  const imageChanged = (index: number, image: Array<string>) => {
    const tImg = [...images];
    tImg[index] = {
      thumbnail: image[0],
      medium: image[1],
      zoom: image[2],
    };
    return setImages(tImg);
  };

  return true ? (
    <div className="UpsertProduct">
      <div className="title">
        <div className="content">
          {method === 'create'
            ? 'Créer un produit'
            : `Modifier le produit "${nameFR}"`}
        </div>
        <div className="actions">
          <IconButton color="primary" aria-label="save" onClick={handleSave}>
            <Save />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="add product"
            onClick={handleDelete}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
      <div>
        <div className="Flex Horizontal SpaceBetween">
          <div className="NameInput">
            <TextField
              id="nameFR"
              label="Nom FR"
              variant="outlined"
              fullWidth
              value={nameFR}
              onChange={(e) => {
                handleValueChanged('nameFR', e);
              }}
            />
          </div>
          <div className="NameInput">
            <TextField
              id="nameEN"
              label="Nom EN"
              variant="outlined"
              fullWidth
              value={nameEN}
              onChange={(e) => {
                handleValueChanged('nameEN', e);
              }}
            />
          </div>
        </div>
        <div className="Flex Horizontal SpaceBetween">
          <div className="SelectInput">
            <TextField
              id="category"
              select
              fullWidth
              label="Categorie"
              value={category}
              onChange={(e) => {
                handleValueChanged('category', e);
              }}
              variant="outlined"
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category}>
                  {t(`${category}`)}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="SelectInput">
            <TextField
              id="subCategory"
              select
              fullWidth
              label="Sous-catégorie"
              value={subCategory}
              onChange={(e) => {
                handleValueChanged('subCategory', e);
              }}
              variant="outlined"
            >
              {getSubCategories(category).map((sc) => (
                <MenuItem key={sc.id} value={sc.id}>
                  {language === 'fr' ? sc.nameFR : sc.nameEN}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="PriceInput">
            <TextField
              id="price"
              label="prix"
              variant="outlined"
              value={price}
              onChange={(e) => {
                handleValueChanged('price', e);
              }}
            />
          </div>
        </div>

        <div className="DescriptionInput">
          <TextField
            id="descriptionFR"
            label="Description FR"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={descriptionFR}
            onChange={(e) => {
              handleValueChanged('descriptionFR', e);
            }}
          />
        </div>
        <div className="DescriptionInput">
          <TextField
            id="descriptionEN"
            label="Description EN"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={descriptionEN}
            onChange={(e) => {
              handleValueChanged('descriptionEN', e);
            }}
          />
        </div>
        <div className="ComponentsInput">
          <TextField
            id="componentsFR"
            label="Composants FR"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={componentsFR}
            onChange={(e) => {
              handleValueChanged('componentsFR', e);
            }}
          />
        </div>
        <div className="ComponentsInput">
          <TextField
            id="componentsEN"
            label="Composants EN"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={componentsEN}
            onChange={(e) => {
              handleValueChanged('componentsEN', e);
            }}
          />
        </div>
        {product ? (
          <div className="imagesInput">
            <ImageUpload
              image={{ thumbnail: '', medium: '', zoom: '' }}
              index={images.length}
              productUID={product!.uid}
              sizes={[175, 300, 1440]}
              onImageChanged={imageChanged}
            ></ImageUpload>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {images.map((image, index) => (
                      <Draggable
                        key={image.thumbnail}
                        draggableId={image.thumbnail}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <ImageUpload
                              image={image}
                              index={index}
                              key={index}
                              productUID={product!.uid}
                              sizes={[175, 300, 1440]}
                              onImageChanged={imageChanged}
                            ></ImageUpload>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <div>Enregistrez le produit pour ajouter des images</div>
        )}
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default UpsertProduct;
