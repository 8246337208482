import React, { useRef } from 'react';
import '../../../App.css';
import { Image } from '../../../types/image';
import { IconButton } from '@material-ui/core';
import Resizer from 'react-image-file-resizer';
import { storage } from '../../../firebase';
import { AddCircle } from '@material-ui/icons';

interface ImageProps {
  image: Image;
  index: number;
  productUID: string;
  sizes: Array<number>;
  onImageChanged: (index: number, images: Array<string>) => void;
}

const resizeFile = (file: File, size = 300) =>
  new Promise<Blob>((resolve) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as Blob);
      },
      'blob'
    );
  });

function ImageUpload({
  image,
  index,
  productUID,
  onImageChanged,
  sizes = [170, 300, 1440],
}: ImageProps) {
  const uploadImage = async (
    productUID: string,
    index: string,
    size: string,
    image: Blob
  ) => {
    const childRef = storage.ref().child(productUID).child(index).child(size);
    await childRef.put(image);
    return await childRef.getDownloadURL();
  };

  const fileInput = useRef<HTMLInputElement>(null);
  const fileUploaded = async () => {
    const files = fileInput?.current?.files;
    if (files?.length) {
      const file = files[0];
      const result: Array<string> = [];
      for (const size of sizes) {
        const resized = await resizeFile(file, size);
        const url = await uploadImage(
          productUID,
          index.toString(),
          size.toString(),
          resized
        );
        result.push(url);
      }
      onImageChanged(index, result);
    }
  };
  return (
    <div
      className={`ImageUpload ${
        image?.thumbnail || image?.medium || image?.zoom ? '' : 'empty'
      }`}
    >
      <input
        accept=".jpg;.png"
        ref={fileInput}
        type="file"
        onChange={fileUploaded}
        className="DisplayNone"
      />
      {image?.thumbnail || image?.medium || image?.zoom ? (
        <div>
          <div className="ImgUploadName">Image {index + 1}: </div>
          <img
            className="ImageUploadThumbnail"
            src={image?.thumbnail || image?.medium || image?.zoom}
            alt="uploaded file"
            onClick={() => {
              fileInput?.current?.click();
            }}
          />
        </div>
      ) : (
        <IconButton
          color="primary"
          aria-label="add product"
          onClick={() => {
            fileInput?.current?.click();
          }}
        >
          <AddCircle />
        </IconButton>
      )}
    </div>
  );
}

export default ImageUpload;
